
<template>
  <div class="content-div" :style="{ width: '100%', height: 'auto', position: 'relative', background: '#F0F2F5' }">
    <div id="mask" v-show="searchLoading">
      <a-spin size="large" tip="请求中..." style="margin: 0 auto"></a-spin>
    </div>
    <div class="info">
      <div class="h_130">
        <a-form id="infoForm" ref='formRef' :model="form" :rules="rules">
          <div class="divFrom">
            <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['startAddress', 'city']" class="from from_input from_input_L">
              <a-cascader v-model:value="startCitys" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setStartAddressMethod" placeholder="请输入起点" />
            </a-form-item>
            <a-button class="from from_buton_change" @click="switchAddressMethod" type="submit" :disabled="(startCitys.length > 0 && endCitys.length > 0) ? false : true" :loading="switchLoading">
              <SwapOutlined style="font-size: 24px;" />
            </a-button>
            <a-form-item :wrapperCol="{ flex: 'auto' }" :name="['endAddress', 'city']" class="from from_input" style="margin-right:0">
              <a-cascader v-model:value="endCitys" :options="$store.state.app.cityDict" :show-search="{ filter }" :changeOnSelect="true" @change="setEndAddressMethod" style="height: 38px;" placeholder="请输入终点" />
            </a-form-item>
            <div style="width:100%;display:flex">
              <a-form-item label="上门取车">
                <a-radio-group v-model:value="pickStatus" @change="isPickCarMethod">
                  <a-radio-button :value="0">不取车</a-radio-button>
                  <a-radio-button :value="2">小板</a-radio-button>
                  <a-radio-button :value="3">地跑</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </div>
            <div style="width:100%" v-if="pickStatus > 0">
              <a-form-item label="取车地址">
                <a-input :addon-before="form.addressData.pick" v-model:value="form.startAddress.details" placeholder="输入详细地址">
                </a-input>
              </a-form-item>
            </div>
            <div style="width:100%;display:flex">
              <a-form-item label="上门送车">
                <a-radio-group v-model:value="takeStatus" @change="isTakeCarMethod">
                  <a-radio-button :value="0">不送车</a-radio-button>
                  <a-radio-button :value="2">小板</a-radio-button>
                  <a-radio-button :value="3">地跑</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </div>
            <div style="width:100%" v-if="takeStatus > 0">
              <a-form-item label="送车地址">
                <a-input :addon-before="form.addressData.take" v-model:value="form.endAddress.details" placeholder="输入详细地址">
                </a-input>
              </a-form-item>
            </div>

            <div style="width:100%;display:flex">
              <a-form-item label="车辆价值" style="margin-right:15px;flex:1">
                <a-input-number   style="height: 32px; border: 1px solid #eee;width:100%" :min="0" placeholder="请输入车辆价值/万" v-model:value="insurance" />
              </a-form-item>
              <!--          <a-form-item v-if="takeStatus === 1||pickStatus === 1" label="详细地址" style="margin-right:15px;flex:1">-->
              <!--            <a-input style="height:32px; border: 1px solid #eee;" placeholder="交车详细地址" v-model="insurance" />-->
              <!--          </a-form-item>-->
              <a-button class="from from_buton" style="margin:0" :loading="searchLoading" :disabled="searchLoading" @click="queryOfferMethod" type="submit">
                <SearchOutlined />
                开始查询
              </a-button>
              <div style="width:200px ;height:30px" class="flex al-center m-l3">
                <a-dropdown :trigger="['click']">
                  <a class="ant-dropdown-link" @click.prevent>
                    历史记录
                    <DownOutlined />
                  </a>
                  <template #overlay>
                    <a-menu>
                      <template v-for="item in queryHistory" :key="item.id">
                        <a-menu-item @click="historyMethod(item)">
                          <a>{{ item.startAddress }} - {{ item.endAddress }}</a>
                        </a-menu-item>
                      </template>

                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
          </div>
        </a-form>
      </div>
      <div class="info_subsidiary">
        <div class="start_end_w" v-if="programmes && programmes.length > 0">
          <div class="programme_div" v-for="(programme, index) in programmes" :key="programme.id" :class="onifPdd[index] ? 'b_b' : ''">
            <div class="programme" @click="selectProgrammeMethod(index)">
              <div class="programme_T" v-if="programmes[index].pdetaileds != null">
                <div class="programme_T_newT">
                  <div class="programme_T_L">
                    方案{{ numArr[index] }}
                  </div>
                  <div v-if="programmes[index].pdetaileds[1] == null" class="programme_T_L_t">直</div>
                  <div v-if="programmes[index].appletChoose == true" class="programme_xcx">小程序</div>
                  <div v-if="programmes[index].state == 0" class="programme_jy">不可发运</div>

                </div>

                <div class="programme_T_R">
                  <CaretDownOutlined />
                </div>
              </div>
              <div class="programme_B">
                <div :class="onifPdd[index] ? 'programme_B_divcenter1' : 'programme_B_divcenter'" class="programme_B_div  text_in">总成本：￥<span :class="onifPdd[index] ? 'c_red' : 'programme_B_divcenter'" class="">{{ programme.totalCost }}</span></div>
                <div :class="onifPdd[index] ? 'programme_B_divcenter1' : 'programme_B_divcenter'" class="programme_B_div ">
                  <!-- 对外报价：￥{{ programme.totalPrice }}  -->
                </div>
                <div :class="onifPdd[index] ? 'programme_B_divcenter1' : 'programme_B_divcenter'" class="programme_B_div  ">
                  总时效：{{ programme.totalTime }}</div>
                <div :class="onifPdd[index] ? 'programme_B_divcenter1' : 'programme_B_divcenter'" class="programme_B_div ">
                  总里程：{{ programme.totalMileage }}</div>
                <div class="programme_B_div text_in">干线费用：￥{{ programme.carFreight }}</div>
                <!-- <div class="programme_B_div">接车费用：￥{{ programme.carCome == 0 ? '0' : programme.carCome }}</div>
                                                                                                            <div class="programme_B_div">送车费用：￥{{ programme.carGo == 0 ? '0' : programme.carGo }}</div> -->
                <div class="programme_B_div">接车费用：
                  <span v-if="pickStatus == 0">不取车</span>
                  <span v-else>
                    <span v-if="programme.startType == 0 || (programme.startType === 2 && form.pickType !== undefined && form.pickType.value === 2) || (programme.startType === 3 && form.pickType !== undefined && form.pickType.value === 3)">￥{{
                        programme.carCome
                      }}</span>
                    <span v-else style="color: red">请询价</span>
                  </span>
                </div>
                <div class="programme_B_div">送车费用：
                  <span v-if="takeStatus == 0">不送车</span>
                  <span v-else>
                    <span v-if="programme.endType == 0 || (programme.endType === 2 && form.takeOutType !== undefined && form.takeOutType.value === 2) || (programme.endType === 3 && form.takeOutType !== undefined && form.takeOutType.value === 3)">￥{{
                        programme.carGo
                      }}</span>
                    <span v-else style="color: red">请询价</span>
                  </span>
                </div>
                <!--            <div class="programme_B_div">接车费用：{{ pickStatus == 0 ? '未选择取车服务' : '￥' + programme.carCome }}</div>-->
                <!--            <div class="programme_B_div">送车费用：{{ takeStatus == 0 ? '未选择送车服务' : '￥' + programme.carGo }}</div>-->
                <div class="programme_B_div">保险费用：￥{{ programme.totalInsurance }}</div>
                <template v-if="programme.carComeRemark">
                  <div class="programme_B_div text_in">接车备注：</div>
                  <div class="programme_B_75_div">{{programme.carComeRemark}}</div>
                </template>
                <template v-if="programme.carComeRemark">
                  <div class="programme_B_div text_in">送车备注：</div>
                  <div class="programme_B_75_div">{{programme.carGoRemark}}</div>
                </template>
              </div>
            </div>
            <div v-if='onifPdd[index]'>
              <a-timeline>
                <a-timeline-item v-for="(pdd, index1) in programmes[index].pdetaileds" :key="pdd.id" :class="index1 === 0 ? 'programme_top' : ''">
                  <template #dot>
                    <div class="programme_radius">{{ index1 + 1 }}</div>
                  </template>
                  <div class="programme_detailed">
                    <div class="programme_B_divcenter text_in">
                      {{ pdd.startAddress }}→{{ pdd.endAddress}}
                    </div>
                    <div class="programme_B">
                      <div class="programme_B_div text_in">成本价：￥{{ pdd.cost }}</div>
                      <div class="programme_B_div">参考报价：￥{{ pdd.offer }}</div>
                      <div class="programme_B_div">参考时效：{{ pdd.time }}</div>
                      <div class="programme_B_div">参考公里：{{ pdd.mileage }}</div>
                      <div class="programme_B_remark text_in">备注：<span class="c_red">{{ pdd.remark }}</span></div>
                    </div>
                  </div>
                </a-timeline-item>
              </a-timeline>
              <!-- <a-button v-if="onifPdd[index]" @click.stop="startOrderMethod(index)" type="primary">下单</a-button> -->
            </div>
            <div style="height:40px;display:flex;justify-content: space-between;" v-if="onifPdd[index]">
              <div>
                <!-- <a-button @click.stop="transDataMethod(index)" type="primary"
                  style="background:#172a8a;width:160px;border:none;left:20px">
                  历史发运数据
                </a-button> -->
              </div>
              <div>
                <a-button type="primary" style="right:25px" @click="createMap(index)">
                  地图预览
                </a-button>
                <a-button :loading="startOrderLoading" @click.stop="startOrderMethod(index)" type="primary" style="background: #ff6b19; border: none; right: 15px">
                  <template #icon>
                    <FormOutlined />
                  </template>
                  下单
                </a-button>
              </div>
            </div>
          </div>
        </div>
        <div v-show="nullData">
          <h1 class="nullDataH1">当前无可发运线路，系统建议路线</h1>
          <div class="noPlanList" :class="item.state === 1 ? 'planList' : ''" v-for="(item, index) in noPlanList" :key="index">
            <a-row>
              <a-col :span="24">
                步骤{{ index + 1 }}
              </a-col>
              <a-col :span="24">
                线路名: {{ item.lineName }}
              </a-col>
              <a-col :span="24">
                承运商: {{ item.carrierName }}
              </a-col>
              <a-col :span="6">
                成本： {{ item.price }}
              </a-col>
              <a-col :span="6">
                时效： {{ item.prescription }}
              </a-col>
              <a-col :span="6">
                公里： {{ item.km }}
              </a-col>
              <a-col :span="24">
                <div style="width: 577px; word-break: break-all;">
                  备注： <span :class="item.state === 0 ? 'noRemark' : ''">{{ item.remark }}</span>
                </div>
              </a-col>
            </a-row>
          </div>
          <div class="null-data-content">
            <!--          <h1 class="title">暂无报价</h1>-->
            <a-button :loading="startOrderLoading" type="primary" class="order-btn" @click="startOrderMethod(index)">
              <template #icon>
                <FormOutlined />
              </template>
              自定义下单
            </a-button>
          </div>
        </div>
      </div>
    </div>

    <div class="drawer-div" v-show="fillShow">
      <a-form-item label="线路名称">
        <div style="display: flex">
          <div>{{ lineName }}</div>
          <div v-show="programmes.length > 0" style="color: #f00; margin-left: 30px">
            成本价: {{ totalCost }}
          </div>
        </div>
      </a-form-item>
      <a-form ref="renderForm" :model="render" :label-col="{ style: { width: '100px' } }" :rules="rules">
        <a-form-item label="是否预约单">
          <a-radio-group v-model:value="pickerForm.isSubscribe">
            <a-radio :value="1">是</a-radio>
            <a-radio :value="0">否</a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item v-show="pickerForm.isSubscribe === 1" label="预约时间" required name="pickAppointTime">
          <a-date-picker v-model:value="render.pickAppointTime" @change="timeChangeMethod" show-time placeholder="请选择时间格式:年-月-日 时:分" format="YYYY-MM-DD HH:mm" />
          <span style="font-size: 12px; color: #f00">（注：预约时间不能超过5天）</span>
        </a-form-item>
      </a-form>
      <div>
        <a-form ref="picker" :model="pickerForm" :label-col="{ style: { width: '100px' } }" :rules="rules">
          <a-form-item label="客户名称" name="pickerName">
            <a-select placeholder="请选择客户" v-model:value="pickerForm.pickerName" @focus="chooseCustomerStatus = true" show-search :open="false"></a-select>
          </a-form-item>
          <a-form-item label="对接人" v-show="
              pickerForm.pickerName !== '' &&
              pickerForm.pickerName !== null &&
              pickerForm.pickerName !== undefined
            ">
            <a-select placeholder="请选择对接人" v-model:value="dto.orderInspectionCustomer.businessContactPerson" @focus="contactShow = true" show-search :open="false"></a-select>
          </a-form-item>
          <a-form-item label="分单员" v-show="
              pickerForm.pickerName !== '' &&
              pickerForm.pickerName !== null &&
              pickerForm.pickerName !== undefined
            ">
            <a-select placeholder="分单员" v-model:value="dto.sourceManId "   >
              <a-select-option v-for="(item, index) in cooperList" :key="index" :value="item.empId">{{ item.empName }}</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="对外报价" name="thirdOfferAmt">
            <a-input-number style="width: 100%;" v-model:value="pickerForm.thirdOfferAmt" @blur="blurBtn"  />
          </a-form-item>
          <a-form-item label="验车打款" name="isInspectionPay">
            <a-select v-model:value="pickerForm.isInspectionPay" style="width: 100%" placeholder="请选择类型">
              <a-select-option :value="1">是</a-select-option>
              <a-select-option :value="0">否</a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="车辆台数" name="vehicles">
            <div style="display: flex">
              <a-input-number v-model:value="pickerForm.vehicles"  @change="vehiclesNumMethod(e)" style="width: 100px" />
              <a-cascader v-model:value="pickerForm.brand" placeholder="请选择车辆型号" :options="carBrands" :show-search="{ filter }" @change="carTypeMethod" style="width: 50%" />
              <a-select v-model:value="pickerForm.vehicleType" style="width: 20%" placeholder="车辆类型">
                <a-select-option :value="1">新车</a-select-option>
                <a-select-option :value="2">二手车</a-select-option>
              </a-select>
              <a-select v-model:value="pickerForm.status" style="width: 20%" placeholder="车辆状况">
                <a-select-option :value="1">正常</a-select-option>
                <a-select-option :value="2">开不动</a-select-option>
                <a-select-option :value="3">事故车</a-select-option>
              </a-select>
              <a-popconfirm placement="topLeft" :title="slot" ok-text="确认" cancel-text="取消" @confirm="forgeryVinNoMethod">
                <a-button class="carId-btn" type="primary" @click="
                    forgeryNum =
                      pickerForm.vehicles -
                      dto.orderInspectionCustomer.orderVehicles.length
                  ">生成</a-button>
                <template #title>
                  <div>
                    确认生成
                    <p style="color: #f00">
                      注:虚拟车辆默认状态跟随您已选择的数据值决定(车型/类型)
                    </p>
                  </div>
                </template>
              </a-popconfirm>
              <a-button class="carId-btn" @click="addOneVeh">+1</a-button>
            </div>

            <a-row type="flex" align="middle" justify="end">
              <a-button type="primary" @click="isBatchShow = true" style="margin: 12px 25px 0px 0px" batchVinNo="">批量生成</a-button>
            </a-row>
          </a-form-item>

          <a-form-item label="">
            <a-collapse :bordered="false" v-model:activeKey="activeKey">
              <template #expandIcon="{ isActive }">
                <caret-right-outlined :rotate="isActive ? 90 : 0" />
              </template>
              <a-collapse-panel key="1" :header="`点击展开${dto.orderInspectionCustomer.orderVehicles.length}条车辆数据`">
                <div v-for="(item, index) in dto.orderInspectionCustomer.orderVehicles" :key="index" class="vehicle-div" @click="vehicleIndex = index">
                  <span style="display: flex; align-items: center">
                    <CloseSquareOutlined style="color: #fff; font-size: 18px; background: #f00" @click="deleteVehicle(index)" />
                  </span>
                  <div style="display: flex; align-items: center">
                    <div style="width: 60px">车辆{{ index + 1 }}：</div>
                    <a-input v-model:value="item.vinNo" style="width: 100px" />
                  </div>
                  <div class="vinNo-div" style="width: 30%">
                    <a-cascader v-model:value="item.vehBrands" placeholder="请选择车辆型号" :options="carBrands" :show-search="{ filter }" @change="vehicleBrandChange" />
                  </div>
                  <div class="vehicleType-div" style="width: 20%">
                    <a-select v-model:value="item.vehicleType" placeholder="请选择类型">
                      <a-select-option :value="1">新车</a-select-option>
                      <a-select-option :value="2">二手车</a-select-option>
                    </a-select>
                  </div>
                  <div class="vehicleType-div" style="width: 20%">
                    <a-select v-model:value="item.status" placeholder="车辆状况">
                      <a-select-option :value="1">正常</a-select-option>
                      <a-select-option :value="2">开不动</a-select-option>
                      <a-select-option :value="3">事故车</a-select-option>
                    </a-select>
                  </div>
                </div>
              </a-collapse-panel>
            </a-collapse>
          </a-form-item>
        </a-form>
        <a-divider />
        <div class="titleH2">
          <h2>起运地交车人</h2>
          <h2><a style="color: #99cc00" @click="sameMethod">同步客户信息</a></h2>
        </div>
        <a-form ref="renderForm" :model="render" :label-col="{ style: { width: '100px' } }" :rules="rules">
          <a-form-item label="交车人姓名" labelAlign name="name">
            <a-input v-model:value="render.name" />
          </a-form-item>
          <a-form-item label="联系电话" name="mobile">
            <a-input-number style="width: 100%" v-model:value="render.mobile"  />
          </a-form-item>
          <a-form-item label="身份证号" name="certificateNumber">
            <a-input v-model:value="render.certificateNumber" placeholder="请输入身份证号码" />
          </a-form-item>
          <div class="titleH2">
            <h2>取车信息</h2>
            <!-- (客户送车地址)cusSendAdd
            地址库起始地 -->
          </div>
          <a-form-item>
            <a-row>
              <a-col :span="2"> </a-col>
              <a-col :span="4">
                {{ pickType[(pickStatus === 0 ? 1 : pickStatus) - 1].label }}
              </a-col>
              <a-col :span="18" v-show="pickStatus > 0">
                {{ form.addressData.pick }} {{ form.startAddress.details }}
              </a-col>
            </a-row>
          </a-form-item>
          <div class="titleH2">
            <h2>送车信息</h2>
            <!-- (客户取车地址)cusPickAdd
            地址库目的地 -->
          </div>
          <a-form-item>
            <a-row>
              <a-col :span="2"> </a-col>
              <a-col :span="4">
                {{ pickType[(takeStatus === 0 ? 1 : takeStatus) - 1].label }}
              </a-col>
              <a-col :span="18" v-show="takeStatus > 0">
                {{ form.addressData.take }} {{ form.endAddress.details }}
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="备注">
            <a-textarea v-model:value="dto.remark" placeholder="请填写备注" />
          </a-form-item>
          <a-form-item>
            <div class="flex ju-between">
              <a-button :loading="orderSaveLoading" type="primary" @click="orderSaveMethod(2)">保存（调度看不见单，不安排）
              </a-button>
              <a-button class="m-l2" :loading="orderSaveLoading" type="primary" @click="orderSaveMethod(1)">下单并确认运输（调度能看见单，立即安排）</a-button>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <ChooseCustomer v-if="chooseCustomerStatus" v-model:visible="chooseCustomerStatus" @select="chooseCustomerMethod" />
    </div>
  </div>
  <a-drawer title="对接人" placement="right" width="30%" :closable="false" v-model:visible="contactShow" @close="closeAddWindow">
    <div>
      <ContactPerson ref="contactPersonRef" :customerId="pickerForm.value.id" :customerName="pickerForm.value.name" @contact="getContact" />
    </div>
  </a-drawer>
  <!-- 地图路线展示 -->
  <a-modal v-model:visible="mapShow" width="50%" title="发运路线预览" footer="" :bodyStyle="{ padding: '0' }">
    <div id="map" :style="{ width: mapWidth }">
      <div v-show="lineShow" class="loading">
        <a-spin size="large" tip="路线加载中..." />
      </div>
    </div>
  </a-modal>

  <a-modal v-model:visible="isBatchShow" @ok="BatchConfig" width="800px" title="批量生成车辆" :bodyStyle="{ padding: '0 0 20 0' }">
    <div>
      <a-form ref="picker" :model="pickerForm" :label-col="{ style: { width: '100px' } }" :rules="rules">
        <a-form-item label="车牌号" name="plateNum">
          <a-textarea v-model:value="pickerForm.plateNum" placeholder="请填写车牌号,用' , '隔开" style="width: 89%" />
          <a-button type="primary" @click="strSplit">拆分</a-button>
          <span style="color: #f00">{{ prompt }}</span>
          <a-button v-show="dto.orderInspectionCustomer.vinNos.length > 0" type="link" class="delete-btn" @click="deleteBtn">删除所有({{ dto.orderInspectionCustomer.vinNos.length }}个)
          </a-button>
        </a-form-item>
        <a-form-item :wrapperCol="{ flex: 'auto' }" validateFirst>
          <template v-for="(vinNo, j) in dto.orderInspectionCustomer.vinNos" :key="j">
            <a-tag @click="delVinNo(j)" :closable="false" style="cursor: pointer">
              <template #icon>
                <span style="color: red"> {{ `${j + 1}`.padStart(3, 0) }}: </span>
              </template>
              {{ vinNo }}
            </a-tag>
          </template>
        </a-form-item>
      </a-form>
    </div>
  </a-modal>

</template>

<script>
import { SearchOutlined, CaretDownOutlined, AlignRightOutlined, FormOutlined, RetweetOutlined, CaretRightOutlined, CloseSquareOutlined, SwapOutlined } from '@ant-design/icons-vue'
import { onMounted, reactive, toRefs, ref } from 'vue'
import { useStore } from 'vuex'
import { carrierAddress } from '@/api/global'
import { message, Modal } from 'ant-design-vue'
import { mobileValidate } from '@/utils/validate'
import { transCarrierData } from '@/api/transport/offer'
import { cline } from '@/api/businessCenter/businessCenter'
import { orderOffer, orderOfferNoConfirm, getCooper } from '@/api/trans/order'
import { carBrandTree } from '@/api/system/setting'
import moment from 'moment'
import arrow from '@/assets/images/arrow.png'
import ChooseCustomer from '@/views/components/chooseCustomer'
import startImg from '@/assets/images/start.png'
import inImg from '@/assets/images/in.png'
import endImg from '@/assets/images/end.png'
import ContactPerson from '@/views/components/contactPerson'
import { locaSetData, locaGetData } from '@/utils/util'
export default {
  components: { startImg, ContactPerson, inImg, endImg, SearchOutlined, CaretDownOutlined, AlignRightOutlined, FormOutlined, ChooseCustomer, RetweetOutlined, CaretRightOutlined, CloseSquareOutlined, SwapOutlined },
  name: 'Map',
  props: {
    gpsTruck: Object,
    endType: {
      type: Number,
      default: 2
    }
  },

  setup (props, context) {
    const picker = ref()
    const contactPersonRef = ref(null)
    const store = useStore()
    const state = reactive({
      cooperList:[],
      queryHistory: [],
      startCitys: [],
      vehicleIndex: 0,
      orderIndex: 0,
      activeKey: '1',
      polyline: [],
      isBatchShow: false,
      batchVinNo: '',
      markers: [],
      endCitys: [],
      address: '',
      startAddress: '',
      totalCost: '',
      mapWidth: '100%',
      myValue: null,
      marker: null,
      indexFlag: 0,
      takeStatus: 0,
      pickStatus: 0,
      lineName: '',
      lineShow: false,
      contactShow: false,
      mapStatus: false, // 判断地图是否已经创建
      mapShow: false,
      fillShow: false,
      orderDrawerShow: true,
      searchLoading: false,
      switchLoading: false,
      startOrderLoading: false,
      orderSaveLoading: false,
      flag: false,
      chooseCustomerStatus: false,
      nullData: false,
      posiArr: [],
      endPosi: [],
      allPosi: [],
      letterArr: [],
      sendAddressList: [], // 客户取车门店list
      pickAddressList: [], // 客户送车门店list
      carBrands: store.state.app.carBrand,
      cityDict: store.state.app.cityDict,
      insurance: null,
      transDataVisible: false,
      loading: false,
      transDataDetails: [],
      forgeryNum: 0,
      prompt: '',
      numArr: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      pickType: [
        {
          label: '否',
          value: 0
        },
        {
          label: '小板',
          value: 2
        },
        {
          label: '地跑',
          value: 3
        }
      ],
      formRef: ref(),
      renderForm: ref(),
      baiduLine: [],
      noPlanList: [],
      ifinfoShow: false,
      infoCssLeft: 210,
      programmes: [],
      dto: {
        id: null,
        orderId: null,
        remark: '',
        consignor: {},
        startAddress: [],
        endAddress: [],
        orderInspectionCustomer: { businessContactMobile: '', businessContactPerson: '', orderVehicles: [], vinNos: [] }
      },
      pickerForm: {
        name: '',
        brand: null,
        isSubscribe: null,
        businessContactPerson: null,
        pickerForm: null,
        isInspectionPay: null,
        vehicleType: null,
        thirdOfferAmt: null,
        value: {},
        vehicles: 0,
        plateVehicles: []
      },

      render: {
        name: '',
        mobile: null,
        pickAppointTime: null,
        isCusPick: null, // 是否到达门店(客户取车)
        cusPickAdd: '', // 客户取车门店
        isCusSend: null, // 是否到达门店(客户送车)
        cusSendAdd: '', // 客户送车门店
        cusSendCarrierId: '',
        cusPickCarrierId: '',
        takeOutDetailedAddress: '',
        detailedAddress: '',
        certificateNumber: '',
        isPick: {
          label: '',
          value: 0
        },
        isTakeOut: {
          label: '',
          value: 0
        }
      },
      form: {
        startAddress: { details: '' },
        start: [],
        value: '0',
        name: '',
        region: '',
        endAddress: { details: '' },
        end: [],
        Come: '0',
        Go: '0',
        addressData: {
          startAddress: '',
          endAddress: '',
          pick: '',
          take: ''
        },
        pickType: { value: null },
        takeOutType: { value: null },
        carCome: [{ label: '不接车', value: '0' }, { label: '地跑', value: '1' }, { label: '小板', value: '2' }],
        carGo: [{ label: '不送车', value: '0' }, { label: '地跑', value: '1' }, { label: '小板', value: '2' }]
      },
      urlArrow: arrow,
      start: '北京',
      end: '成都',
      onifPdd: [],
      rules: {
        startAddress: {
          city: { required: true, message: '请选择起点城市', trigger: 'blur' },
          details: { required: true, message: '请填写取车详细地址' }
        },
        endAddress: {
          city: { required: true, message: '请选择终点城市', trigger: 'blur' },
          details: { required: true, message: '请填写送车详细地址' }
        },
        isInspectionPay: { required: true, message: '是否验车打款', trigger: 'blur', type: 'number' },
        mobile: [{ required: true, message: '请填写电话号码', trigger: 'blur',type: "number"  }, { pattern: mobileValidate, message: '请填写正确的电话号码', trigger: 'blur' }],
        pickerName: { required: true, message: '请选择客户', trigger: 'change' },
        thirdOfferAmt: { required: true, message: '请填写客户报价', trigger: 'blur' ,type: "number"},
        name: { required: true, message: '请填写交车人姓名', trigger: 'blur' },
        certificateNumber: { required: false, message: '请填写正确的身份证号', trigger: 'blur' },
        pickAppointTime: { required: true, message: '请填写拖车时间', trigger: 'blur' },
        isPick: { required: true, message: '是否上门取车', trigger: 'change', type: 'object' },
        pickType: { value: { required: false, message: '请选择取车方式', trigger: 'blur', type: 'number' } },
        takeOutType: { value: { required: false, message: '请选择送车方式', trigger: 'blur', type: 'number' } }
      }
    })
    const disabledDate = current => {
      // Can not select days before today and today
      console.log(current, moment().endOf('day'))
      return current && current < moment().endOf('day') && current < moment().endOf('day')
    };
    const disabledDateTime = () => {
      // return {
      //   disabledHours: () => range(0, 24).splice(4, 20),
      //   disabledMinutes: () => range(30, 60),
      //   disabledSeconds: () => [55, 56],
      // };
    };
    //点击历史记录
    const historyMethod = (item) => {
      if (item.startAreaId) {
        state.startCitys = [item.startProvinceId, item.startCityId, item.startAreaId]
        state.form.startAddress.area = item.startAreaId
        state.form.start = [
          { label: item.startProvince },
          { label: item.startCity },
          { label: item.startArea }
        ]
      } else {
        state.startCitys = [item.startProvinceId, item.startCityId]
        state.form.start = [
          { label: item.startProvince },
          { label: item.startCity },
        ]
      }
      if (item.endAreaId) {
        state.endCitys = [item.endProvinceId, item.endCityId, item.endAreaId]
        state.form.endAddress.area = item.endAreaId
        state.form.end = [
          { label: item.endProvince },
          { label: item.endCity },
          { label: item.endArea }
        ]
      } else {
        state.endCitys = [item.endProvinceId, item.endCityId]
        state.form.end = [
          { label: item.endProvince },
          { label: item.endCity }
        ]
      }
      if (item.isPick === 1) {
        state.render.isPick = { value: 1, label: '是' }
      } else {
        state.render.isPick = { value: 0, label: '否' }
      }
      if (item.isTakeOut === 1) {
        state.render.isTakeOut = { value: 1, label: '是' }
      } else {
        state.render.isTakeOut = { value: 0, label: '否' }
      }
      state.form.startAddress.city = item.startCityId
      state.form.startAddress.province = item.startProvinceId

      state.form.endAddress.city = item.endCityId
      state.form.endAddress.province = item.endProvinceId
      state.pickStatus =  item.pickType.value ? item.pickType.value : 0
      state.form.pickType = item.pickType
      state.form.takeOutType = item.takeOutType

      state.takeStatus =  item.takeOutType.value ? item.takeOutType.value : 0
      state.insurance = item.insurance ? item.insurance : null
      state.form.endAddress.details = item.endDetails
      state.form.startAddress.details = item.startDetails
      queryClineApi(item)
    }
    // 创建地图实例
    let map = ref(null)
    const createMap = index => {
      state.mapShow = true
      state.lineShow = true
      setTimeout(() => {
        if (!state.mapStatus) {
          let timer = null
          map = new LKMap.Map('map', {
            center: [116.4124519634895, 39.9043382673205],      // 中心点-北京市
            zoom: 4,   // 缩放级别  // 地图样式
          })
          timer = setTimeout(() => {
            map.destroy()
          }, 3000)
          map.on("load", function () {
            state.mapStatus = true
            document.querySelector('.LK-map-logo').style.display = 'none'
            mapLine(index)
            clearTimeout(timer)
          })
        } else {
          document.querySelector('.LK-map-logo').style.display = 'none'
          mapLine(index)
        }
      }, 1000)
    }
    // 获取并返回经纬度
    const getPosition = async function (data) {
      let position = []
      const a = data.map((item, index) => {
        var myGeo = new LKMap.AnalysisSearch()
        return new Promise(resolve => {
          myGeo.search(
            item.name,
            (status, result) => {
              if (result) {
                position.unshift({ id: item.id, point: { lon: result.region.center_point.lon, lat: result.region.center_point.lat } })
              }
              resolve()
            }
          )
        })
      })
      await Promise.all(a)
      return position
    }
    // 加载货运路线数据
    const loadLine = async (i, data) => {
      // map.clearOverlays() // 清除地图上所有的覆盖物

      const localPosition = [
      ]
      //如果存在则 直接读取缓存数据
      if (state.baiduLine && state.baiduLine.length > 0 && state.baiduLine[i]) {
        // if (state.posiArr.length > 0) { // 动态地图标志-暂注释
        //   if (state.pickStatus === 1) state.baiduLine[state.indexFlag].position[0] = state.posiArr[0]
        //   if (state.takeStatus === 1) {
        //     if (state.baiduLine[state.indexFlag].position.length > 2) state.baiduLine[state.indexFlag].position[2] = state.posiArr[2]
        //     else state.baiduLine[state.indexFlag].position[1] = state.posiArr[1]
        //   }
        // }
        markMap(state.baiduLine[i].position)
      } else {
        const position = getPosition(data)
        position.then(res => {
          res.sort(function (a, b) {
            return a.id - b.id
          })
          res.map((item) => {
            localPosition.push(item.point)
          })
          // 缓存下来
          state.baiduLine[i] = { id: i, position: localPosition }
          state.allPosi = localPosition
          state.posiArr = localPosition
          markMap(localPosition) // 地图绘制线路
        })
      }
    }
    // 绘制货运路线
    const markMap = (path) => {
      if (state.marker !== null) state.marker.remove()
      let TruckDrivingService = new LKMap.TruckDriving_v2({
        costing: 'truck',	  // 路线规划方式  truck: 货车
        alternates: 0	      //查找至多n条路线（n =< 3）
      })
      let params = {
        locations: path,
        costing: 'truck',	  // 路线规划方式  truck: 货车
        alternates: 0	      //查找至多n条路线（n =< 3）
      }
      TruckDrivingService.search(params, (status, result) => {
        // console.log('货车路线规划v2', status, result)
        showLine(result)	// 绘制线路
        addIcon()			// 添加起点坐标
      })
      // 添加起点终点
      function addIcon () {
        state.markers = [];
        for (var i in path) {
          var anchor = "center";
          var icon = new LKMap.Icon({
            size: new LKMap.Size(30, 30),
            image: inImg,
            imageSize: new LKMap.Size(30, 30),
            // imageOffset: new LKMap.Pixel(0, 0)
          })
          if (i == 0) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: startImg,
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            })
            anchor = 'bottom';
          } else if (i == path.length - 1) {
            icon = new LKMap.Icon({
              size: new LKMap.Size(39, 48),
              image: endImg,
              imageSize: new LKMap.Size(39, 48),
              // imageOffset: new LKMap.Pixel(0, 0)
            })
            anchor = "bottom"
          }
          var position = new LKMap.LngLat(path[i]['lon'], path[i]['lat'])
          state.markers[i] = new LKMap.Marker({
            position: position,
            icon: icon,
            anchor: 'bottom',
          })
          state.markers[i].setMap(map)
        }
      }

      // 绘制线路
      function showLine (result) {
        clearMarker()
        state.polyline = []
        var color = ['#a9a4a4', '#3471e2', '#e44d33']
        for (var i in result.routes) {
          state.polyline[i] = new LKMap.Polyline({
            path: result.routes[i].geometry.coordinates,
            strokeWeight: 8,
            lineJoin: 'round',
            lineCap: 'round',
            strokeColor: '#3471e2',
            showDir: true, // 线路箭头指示
          });
          state.polyline[i].setMap(map)
        }
        map.setFitView(null, {
          padding: {
            top: 100,
            bottom: 100,
            left: 0,
            right: 0,
          }
        })
        setTimeout(() => {
          state.lineShow = false
        }, 50)
      }
    }
    const mapLine = (i) => {
      if (state.programmes != null && state.programmes[i] !== undefined && state.programmes[i].pdetaileds !== undefined && state.programmes[i].pdetaileds !== null) {
        const data = []
        const lineSize = state.programmes[i].pdetaileds.length
        state.programmes[i].pdetaileds.map((item, index) => {
          data.push({ id: index, name: item.startAddress })
          if (lineSize === index + 1) {
            data.push({ id: index + 1, name: item.endAddress })
          }
        })
        // data.push({ id: 0, name: state.addressData.pick })
        // data.push({ id: 1, name: state.addressData.take })
        setTimeout(() => {
          loadLine(i, data)
        }, 100)
      }
    }
    const clearMarker = () => {
      state.markers.forEach(item => {
        item.remove()
      })
      state.polyline.forEach(item => {
        item.remove()
      })
    }
    // 选择的方案进行高亮
    const selectProgrammeMethod = (index) => {
      for (let i = 0; i < state.onifPdd.length; i++) {
        if (i === index) {
          if (state.onifPdd[i] === true) { state.onifPdd[i] = false } else {
            state.onifPdd[i] = true
            state.indexFlag = i
          }
        } else {
          state.onifPdd[i] = false
        }
      }
    }
    // 起始地目的地切换
    const switchAddressMethod = async () => {
      state.switchLoading = true
      // 城市id切换
      const storeCityId = [state.startCitys, state.endCitys]
      state.startCitys = storeCityId[1]
      state.endCitys = storeCityId[0]
      const storeAddress = [state.form.startAddress, state.form.endAddress]
      state.form.startAddress = storeAddress[1]
      state.form.endAddress = storeAddress[0]
      // 城市对象切换
      const storeCity = [state.form.start, state.form.end]
      state.form.start = storeCity[1]
      state.form.end = storeCity[0]
      // 是否取送车状态切换
      const storeStatus = [state.pickStatus, state.takeStatus]
      if (storeStatus[1]) state.pickStatus = storeStatus[1]
      if (storeStatus[0]) state.takeStatus = storeStatus[0]
      // 取送车方式切换
      const storePickType = [state.form.pickType, state.form.takeOutType]
      state.form.pickType = storePickType[1]
      state.form.takeOutType = storePickType[0]
      // 取送车 gps 数据切换
      const storeAddressData = JSON.parse(JSON.stringify(state.form.addressData))
      state.form.addressData.pick = storeAddressData.take
      state.form.addressData.take = storeAddressData.pick
      state.form.addressData.startAddress = storeAddressData.endAddress
      state.form.addressData.endAddress = storeAddressData.startAddress
      state.form.addressData.startGps = storeAddressData.endGps
      state.form.addressData.endGps = storeAddressData.startGps
      await queryOfferMethod()
    }
    // 价格查询
    const queryOfferMethod = status => {
      state.formRef.validate()
        .then(() => {
          if (state.render.isPick.value > 0) {
            if (!state.form.startAddress.area) {
              message.warn('取车时，请选择起点的区，不然获取不到取车费用')
            }
          }
          if (state.render.isTakeOut.value > 0) {
            if (!state.form.endAddress.area) {
              message.warn('送车时，请选择终点的区，不然获取不到送车费用')
            }
          }
          // 关闭下单
          // orderDrawerCloseMethod()
          const queryDTO = {
            endAreaId: state.form.endAddress.area,
            endCityId: state.form.endAddress.city,
            endProvinceId: state.form.endAddress.province,
            endCity: state.form.end[1].label,
            endProvince: state.form.end[0].label,
            insurance: state.insurance,
            startAreaId: state.form.startAddress.area,
            startCityId: state.form.startAddress.city,
            startProvinceId: state.form.startAddress.province,
            startCity: state.form.start[1].label,
            startProvince: state.form.start[0].label,
            isPick: state.render.isPick.value,
            isTakeOut: state.render.isTakeOut.value,
            pickType: state.form.pickType,
            takeOutType: state.form.takeOutType,
            startGps: state.form.addressData.startGps,
            endGps: state.form.addressData.endGps,
            endAddress: state.form.addressData.endAddress,
            startAddress: state.form.addressData.startAddress,
            endDetails: state.form.endAddress.details,
            startDetails: state.form.startAddress.details,
            vehicles: [{
              cost: state.insurance
            }],
            endType: props.endType // B端为2 C端为3
          }
          if (state.form.end[2] != null) {
            queryDTO.endArea = state.form.end[2].label
          }
          if (state.form.start[2] != null) {
            queryDTO.startArea = state.form.start[2].label
          }
          if (queryDTO.startArea !== undefined) {
            queryDTO.startAddress = queryDTO.startProvince + queryDTO.startCity + queryDTO.startArea + queryDTO.startAddress
          } else {
            queryDTO.startAddress = queryDTO.startProvince + queryDTO.startCity + queryDTO.startAddress
          }
          if (queryDTO.endArea !== undefined) {
            queryDTO.endAddress = queryDTO.endProvince + queryDTO.endCity + queryDTO.endArea + queryDTO.endAddress
          } else {
            queryDTO.endAddress = queryDTO.endProvince + queryDTO.endCity + queryDTO.endAddress
          }
          queryClineApi(queryDTO)
        })
    }
    // 价格查询 接口
    const queryClineApi = queryDTO => {
      state.fillShow = false
      context.emit('queryHistory', queryDTO)
      state.ifinfoShow = true
      state.searchLoading = true
      cline(queryDTO).then((res) => {
        locaSetData('queryHistory', queryDTO)
        state.queryHistory = locaGetData('queryHistory')
        if (res.code === 10000) {
          locaSetData('queryHistory', queryDTO)
          if (res.data.planList == null) {
            state.nullData = true
            state.programmes = []
            state.noPlanList = res.data.noPlanList
          } else {
            state.nullData = false
            state.programmes = res.data.planList
          }
          // 如果搜索的时候  在下单状态就需要更新下单信息
          if (state.orderDrawerShow === true) {
            // 如果重新搜索的 城市下标没有这么多就默认改成0
            if (state.orderIndex < state.programmes.length) {
              // startOrderMethod(state.orderIndex)
            } else {
              message.error('因更换了城市,之前选择的方案不存在,所以默认修改为新的方案一')
              // startOrderMethod(0)
              selectProgrammeMethod(0)
            }
          }
          setTimeout(function () {
            for (let i = 0; i < state.form.end.length & i < 2; i++) {
              if (i === 1 && state.form.end[0].label !== '北京市' && state.form.end[0].label !== '上海市' && state.form.end[0].label !== '重庆市' && state.form.end[0].label !== '天津市') {
                state.end = state.form.end[i].label
              } else {
                state.end = state.form.end[0].label
              }
            }
            for (let i = 0; i < state.form.start.length & i < 2; i++) {
              if (i === 1 && state.form.start[0].label !== '北京市' && state.form.start[0].label !== '上海市' && state.form.start[0].label !== '重庆市' && state.form.start[0].label !== '天津市') {
                state.start = state.form.start[i].label
              } else {
                state.start = state.form.start[0].label
              }
            }
            state.onifPdd.length = 0
            if (state.programmes != null) {
              for (let i = 0; i < state.programmes.length; i++) {
                state.onifPdd.push(false)
              }
            }
            state.onifPdd[0] = true
          }, 1000)
        }
        state.baiduLine = []
      }).catch((e) => {
        console.log(e)
        state.programmes = []
      }).finally(() => {
        setTimeout(function () {
          state.searchLoading = false
          state.switchLoading = false
        }, 500)
      })
    }
    const setStartAddressMethod = (value, selectedOptions) => {
      state.form.start = selectedOptions
      state.form.startAddress.province = value[0]
      state.form.startAddress.city = value[1]
      state.form.startAddress.area = value[2]
      if (state.form.start.length > 1) {
        state.form.addressData.pick = state.form.start[0].label + state.form.start[1].label
        state.form.addressData.startAddress = ''
        if (state.form.start.length > 2) {
          state.form.addressData.pick = state.form.start[0].label + state.form.start[1].label + state.form.start[2].label
          state.form.addressData.startAddress = ''
        }
      }
      if (state.form.endAddress.city) {
        queryOfferMethod()
      }
    }

    const setEndAddressMethod = (value, selectedOptions) => {
      state.form.end = selectedOptions
      state.form.endAddress.province = value[0]
      state.form.endAddress.city = value[1]
      state.form.endAddress.area = value[2]
      if (state.form.end.length > 1) {
        state.form.addressData.take = state.form.end[0].label + state.form.end[1].label
        state.form.addressData.endAddress = ''
        if (state.form.end.length > 2) {
          state.form.addressData.take = state.form.end[0].label + state.form.end[1].label + state.form.end[2].label
          state.form.addressData.endAddress = ''
        }
      }
      if (state.form.startAddress.city) {
        queryOfferMethod()
      }
    }

    const filter = (inputValue, path) => {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
    }
    onMounted(() => {
      state.queryHistory = locaGetData('queryHistory')
      state.carBrands = store.state.app.carBrand
      // 如果有数据 就重新加载
      if (state.carBrands.length <= 0) {
        carBrandTree({}).then(res => {
          if (res.code === 10000) state.carBrands = res.data
        })
      }
      // 虚拟号 添加字母
      for (let i = 0; i < 26; i++) {
        state.letterArr.push(String.fromCharCode(65 + i))
      }
      setTimeout(() => {
        document.querySelectorAll('.ant-cascader-menu').forEach(item => {
          item.style.height = '360px'
        })
      }, 500)
    })
    const getCooperFun = (id) => {
      getCooper(id,{}).then((res) => {
        if (res.code === 10000) {
          state.cooperList = res.data.empList;
        }
      });
    }
    // 客户选择返回
    const chooseCustomerMethod = item => {
      state.pickerForm.value = item
      state.pickerForm.pickerName = item.name
      state.dto.orderInspectionCustomer.businessContactMobile = ''
      state.dto.orderInspectionCustomer.businessContactPerson = ''
      state.dto.sourceManId = null;
      getCooperFun(item.id)
    }
    // 点击下单
    const startOrderMethod = (index) => {
      state.startOrderLoading = true
      state.orderIndex = index
      // 如果开始车型 没有获取到就重新获取一下
      if (state.carBrands.length <= 0) {
        carBrandTree({}).then(res => {
          state.carBrands = res.data
        })
      }
      // 有线路方案的时候
      if (state.programmes && state.programmes.length > 0) {
        state.render.cusPickAdd = null
        state.render.cusSendAdd = null
        state.render.isCusPick = null
        state.render.isCusPick = null
        state.fillShow = true
        state.totalCost = state.programmes[index].totalCost
        state.lineName = state.programmes[index].startAddress + '-' + state.programmes[index].endAddress
        // 报价车辆信息
        state.dto.orderInspectionCustomer.lineName = state.lineName
        state.dto.orderInspectionCustomer.amt = state.programmes[index].totalCost
        state.dto.orderInspectionCustomer.offer = JSON.stringify(state.programmes[index])
      } else {
        // 自定义下单的时候
        state.totalCost = 0
        state.lineName = ""
        if (state.noPlanList && state.noPlanList.length > 0) {
          state.noPlanList.forEach(e => {
            // 成本相加
            state.totalCost = state.totalCost + Number(e.price)
            // 线路相加
            if (state.lineName == "") {
              state.lineName = e.lineSingleName
            } else {
              state.lineName = state.lineName + "-" + e.lineSingleName
            }
          })
        }
      }
      state.fillShow = true
      state.startOrderLoading = false
    }
    // 关闭下单
    const orderDrawerCloseMethod = () => {
      // state.orderDrawerShow = false
      state.mapWidth = '100%'
    }

    // 保存并下单
    const orderSaveMethod = (type) => {
      console.log(state.pickerForm, state.render);
      picker.value.validate()
        .then(() => {
          // 判断是否有多辆车
          if (state.dto.orderInspectionCustomer.orderVehicles.length > 0) {
            state.render.startAddress = {}
            if (state.dto.orderInspectionCustomer.orderVehicles.length !== Number(state.pickerForm.vehicles)) { // state.pickerForm.vehicles为车辆台数
              state.prompt = '车牌号个数与车辆台数不符合'
            } else {
              state.prompt = ''
              state.dto.vehicleCost = state.insurance
              state.dto.isInspectionPay = state.pickerForm.isInspectionPay
              state.dto.isSubscribe = state.pickerForm.isSubscribe
              state.dto.consignor = state.render
              state.dto.consignor.detailedAddress = state.form.startAddress.details // 取车地址 detailedAddress
              state.dto.consignor.takeOutDetailedAddress = state.form.endAddress.details
              state.dto.consignor.pickType = state.form.pickType
              state.dto.consignor.takeOutType = state.form.takeOutType
              state.dto.endAddress = state.form.endAddress
              state.dto.startAddress = state.form.startAddress
              state.dto.pickAppointTime = state.dto.consignor.transTime
              state.dto.orderInspectionCustomer.customerId = state.pickerForm.value.id
              state.dto.orderInspectionCustomer.thirdOfferAmt = state.pickerForm.thirdOfferAmt
              state.dto.orderInspectionCustomer.numberOfVehicles = state.pickerForm.vehicles
              state.renderForm.validate()
                .then(() => {
                  orderOfferApi(type)
                })
            }
          } else message.error('请填写车牌号或生成虚拟号')
        }).catch(err => console.log(err))
    }
    // 订单保存api
    const orderOfferApi = (type) => {
      state.orderSaveLoading = true
      let reqData = type === 1 ? orderOffer : orderOfferNoConfirm
      reqData({ ...state.dto }).then(res => {
        if (res.code === 10000) {
          message.success(res.msg)
          // 关闭下单
          orderDrawerCloseMethod()
          // 数据清空
          orderDataClean()
        }
      }).catch(err => console.log(err)).finally(() => {
        setTimeout(function () {
          state.orderSaveLoading = false
        }, 1000)
      })
    }
    // 订单保存成本清除
    const orderDataClean = () => {
      document.body.scrollTop = 0
      // 提交请求后数据清空
      state.lineName = ''
      state.totalCost = 0
      const object = { isPick: state.render.isPick, isTakeOut: state.render.isTakeOut }
      state.dto.orderInspectionCustomer.orderVehicles = []
      setTimeout(() => {
        state.render.isPick = object.isPick
        state.render.isTakeOut = object.isTakeOut
        state.fillShow = false
      }, 500)
    }
    // 判断车辆台数是否大于100
    const vehiclesNumMethod = (e) => {
      if (Number(state.pickerForm.vehicles) >= 100) state.pickerForm.vehicles = 100
    }
    // 生成虚拟号码
    const forgeryVinNoMethod = () => {
      // state.dto.orderInspectionCustomer.orderVehicles = []
      // state.dto.orderInspectionCustomer.vinNos = []
      let vlength = state.dto.orderInspectionCustomer.orderVehicles.length;
      let index = state.pickerForm.vehicles
      for (let i = vlength; i < index; i++) {
        if (state.dto.orderInspectionCustomer.orderVehicles.length < Number(state.pickerForm.vehicles)) {
          let data = {
            vinNo: '虚' + state.letterArr[Math.round(Math.random() * 25)] + Math.round(Math.random() * (99999 - 10000) + 10000),
            brand: state.dto.orderInspectionCustomer.brand,
            brandId: state.dto.orderInspectionCustomer.brandId,
            model: state.dto.orderInspectionCustomer.model,
            modelId: state.dto.orderInspectionCustomer.modelId,
            vehicleType: state.pickerForm.vehicleType,
            status: state.pickerForm.status,
            vehBrands: [state.dto.orderInspectionCustomer.brandId, state.dto.orderInspectionCustomer.modelId]
          }
          if (state.dto.orderInspectionCustomer.orderVehicles.includes(data)) index++ // 判断虚拟车架号去重
          else state.dto.orderInspectionCustomer.orderVehicles.push(data), state.dto.orderInspectionCustomer.vinNos.push(data.vinNo)
        } else message.error('输入或添加的车牌号大于车辆台数/总数')
      }
    }
    // 车辆数据+1
    const addOneVeh = () => {

      let data = {
        vinNo: '虚' + state.letterArr[Math.round(Math.random() * 25)] + Math.round(Math.random() * (99999 - 10000) + 10000),
        brand: state.dto.orderInspectionCustomer.brand,
        brandId: state.dto.orderInspectionCustomer.brandId,
        model: state.dto.orderInspectionCustomer.model,
        modelId: state.dto.orderInspectionCustomer.modelId,
        vehicleType: state.pickerForm.vehicleType,
        status: state.pickerForm.status,
        vehBrands: [state.dto.orderInspectionCustomer.brandId, state.dto.orderInspectionCustomer.modelId]
      }
      let status = state.dto.orderInspectionCustomer.orderVehicles.indexOf(item => item.vinNo != data.vinNo)
      if (status === -1) state.dto.orderInspectionCustomer.orderVehicles.push(data), state.dto.orderInspectionCustomer.vinNos.push(data.vinNo)
      else message.error('异常：请重试')
      state.pickerForm.vehicles = state.dto.orderInspectionCustomer.orderVehicles.length
    }
    // 删除车辆数据
    const deleteVehicle = index => {
      state.dto.orderInspectionCustomer.orderVehicles.splice(index, 1)
      state.dto.orderInspectionCustomer.vinNos.splice(index, 1)
      state.pickerForm.vehicles = state.dto.orderInspectionCustomer.orderVehicles.length
    }
    // 单台车型
    const vehicleBrandChange = (e, v) => {
      state.dto.orderInspectionCustomer.orderVehicles[state.vehicleIndex].brand = v[0].label
      state.dto.orderInspectionCustomer.orderVehicles[state.vehicleIndex].brandId = v[0].value
      state.dto.orderInspectionCustomer.orderVehicles[state.vehicleIndex].model = v[1].label
      state.dto.orderInspectionCustomer.orderVehicles[state.vehicleIndex].modelId = v[1].value
    }
    // 上门送车(new)
    const isTakeCarMethod = val => {
      if (state.takeStatus === 0) {
        state.render.isTakeOut = { value: 0, label: '否' }
        state.form.takeOutType = {}
        state.rules.takeOutType.value.required = false
        state.form.startAddress.details = ''
        queryOfferMethod()
      } else {
        // 重新构造 取车的数据
        state.form.takeOutType = state.pickType[state.takeStatus - 1]
        state.render.isTakeOut = { value: 1, label: '是' }
        queryOfferMethod()
      }
    }
    // 送车方式
    const takeTypeMethodChange = (val, res) => {
      state.form.takeOutType = res
      queryOfferMethod(true)
    }
    // 同步客户信息
    const sameMethod = () => {
      state.render.name = state.pickerForm.value.name
      state.render.mobile = Number(state.pickerForm.value.mobile) 
      state.render.certificateNumber = state.pickerForm.value.certificateNumber
    }
    // 请选择车辆型号
    const carTypeMethod = (value) => {
      let carType = state.carBrands.find(item => item.value === value[0]) // 车辆品牌
      let carTypeDetail = carType.children.find(item => item.value === value[1]) // 具体型号
      state.dto.orderInspectionCustomer.brand = carType.label
      state.dto.orderInspectionCustomer.brandId = carType.value
      state.dto.orderInspectionCustomer.model = carTypeDetail.label
      state.dto.orderInspectionCustomer.modelId = carTypeDetail.value
    }
    // 选择预约时间
    const timeChangeMethod = (date, dateString) => {
      state.render.pickAppointTime = dateString
    }

    // 历史发运数据
    const transDataMethod = (index) => {
      state.transDataDetails = []
      if (state.programmes.length > 0) {
        const pdetaileds = state.programmes[index].pdetaileds
        let cityNames = undefined
        if (pdetaileds.length > 1) {
          pdetaileds.pop()
          cityNames = pdetaileds.map(column => { return column.endAddress.replace("市", "") })
        }
        const queryDTO = {
          cityNames: cityNames,
          endArea: state.form.endAddress.area,
          endCity: state.form.endAddress.city,
          endProvince: state.form.endAddress.province,
          pickType: state.form.Come,
          takeOutType: state.form.Go,
          startArea: state.form.startAddress.area,
          startCity: state.form.startAddress.city,
          startProvince: state.form.startAddress.province
        }
        state.transDataVisible = true
        state.loading = true
        transCarrierData(queryDTO).then((res) => {
          if (res.code === 10000) {
            state.transDataDetails = res.data
          }
        }).finally(() => {
          state.loading = false
        })
      }
    }
    // 上门取车
    const isPickCarMethod = val => {
      if (state.pickStatus === 0) {
        state.render.isPick = { value: 0, label: '否' }
        state.form.pickType = {}
        state.rules.pickType.value.required = false
        state.form.startAddress.details = ''
        queryOfferMethod()
      } else {
        // 重新构造 取车的数据
        state.form.pickType = state.pickType[state.pickStatus - 1]
        state.render.isPick = { value: 1, label: '是' }
        queryOfferMethod()
      }
      // state.flag = true
      // if (val.value === 0) {
      //   state.form.pickType = { value: null, label: null }
      //   state.form.addressData.startAddress = ''
      //   // queryOfferMethod(true)
      // }
    }
    // 取车方式
    const pickTypeMethod = (val, res) => {
      state.form.pickType = res
      queryOfferMethod(true)
    }
    // 获取门店list
    const getPickAddress = () => { // 获取承运商地址库  客户取车地址 地址库根据目的地搜索
      carrierAddress({
        isStore: 1,
        addressProvince: state.form.endAddress.province,
        addressCity: state.form.endAddress.city
      }).then(res => {
        let arr = []
        res.data.forEach(item => {
          item.address.forEach((v, i) => {
            arr.push({ name: item.name + '-' + v, carrierId: item.carrierId })
          })
        })
        setTimeout(() => { state.pickAddressList = arr }, 100)
      })
    }
    const getSendAddress = () => { // 获取承运商地址库  客户送车地址 地址库根据起始地搜索
      carrierAddress({
        isStore: 1,
        addressProvince: state.form.startAddress.province,
        addressCity: state.form.startAddress.city
      }).then(res => {
        let arr = []
        res.data.forEach(item => {
          item.address.forEach((v, i) => {
            arr.push({ name: item.name + '-' + v, carrierId: item.carrierId })
          })
        })
        state.sendAddressList = arr
      })
    }
    // 城市名称返回id
    const cityNameMethod = (province, city, district, type) => {
      if (type === true) {
        if (state.form.start[0].label != province || state.form.start[1].label != city) {
          console.log("起点城市不对", state.form.start[0].label, state.form.start[1].label)
          message.error("解析地址跟选择地址不服 请重新搜索或者换更详细的地址搜索")
          return
        }
      } else {
        if (state.form.end[0].label != province || state.form.end[1].label != city) {
          console.log("终点城市不对", state.form.end[0].label, state.form.end[1].label)
          message.error("解析地址跟选择地址不服 请重新搜索或者换更详细的地址搜索")
          return
        }
      }
      store.state.app.cityDict.forEach(e => {
        if (e.label == province) {
          e.children.forEach(c => {
            if (c.label == city) {
              c.children.forEach(a => {
                if (a.label == district) {
                  console.log("a.label", a.label)
                  if (type === true) {
                    state.form.start = [
                      { value: e.value, label: e.label },
                      { value: c.value, label: c.label },
                      { value: a.value, label: a.label }
                    ]
                    state.form.startAddress.province = e.value
                    state.form.startAddress.city = c.value
                    state.form.startAddress.area = a.value
                    state.startCitys = [e.value, c.value, a.value]
                  } else {
                    state.form.end = [
                      { value: e.value, label: e.label },
                      { value: c.value, label: c.label },
                      { value: a.value, label: a.label }
                    ]
                    state.form.endAddress.province = e.value
                    state.form.endAddress.city = c.value
                    state.form.endAddress.area = a.value
                    state.endCitys = [e.value, c.value, a.value]
                  }
                }
              })
            }
          })
        }

      })
    }
    // 确认车牌号
    const strSplit = () => {
      if (state.pickerForm.plateNum.length > 0) {
        const splitVinNos = (state.pickerForm.plateNum).trim().split(/[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={ }|《》？：“”【】、；‘'，。、\s]/im).filter(item => item !== '')
        const newVinNos = []
        const repeatVin = []
        const map = new Map()
        for (const vinNo of splitVinNos) {
          if (map.has(vinNo)) {
            // console.log(vinNo)
            if (vinNo.length <= 20 && vinNo.length >= 6) {
              repeatVin.push(vinNo)
              state.prompt = ''
            } else {
              state.prompt = '输入的车牌号不正确,已自动删除错误的车牌号'
            }
          } else {
            map.set(vinNo)
            if (vinNo.length <= 20 && vinNo.length >= 6) {
              newVinNos.push(vinNo)
              state.prompt = ''
            } else {
              state.prompt = '输入的车牌号不正确,已自动删除错误的车牌号'
            }
          }
        }
        if (repeatVin.length > 0) {
          Modal.warning({
            content: `车架号 ${repeatVin.join(',')} 重复，已自动去重`
          })
        }

        state.pickerForm.plateNum = []
        if (state.dto.orderInspectionCustomer.vinNos.length > 0) {
          state.pickerForm.plateVehicles = newVinNos
          newVinNos.forEach(item => {
            if (!state.dto.orderInspectionCustomer.vinNos.includes(item)) state.dto.orderInspectionCustomer.vinNos.unshift(item)
            // console.log('123', state.dto.orderInspectionCustomer.vinNos.filter(i => i === item).length)
          })
        } else {
          state.dto.orderInspectionCustomer.vinNos = newVinNos
        }
      } else {
        message.error('车牌号为空')
      }
    }
    // 删除所有号码
    const deleteBtn = () => {
      state.pickerForm.plateNum = []
      state.pickerForm.plateVehicles = []
      state.pickerForm.plateNum = []
      state.dto.orderInspectionCustomer.vinNos = []
    }
    const delVinNo = (index) => {
      state.dto.orderInspectionCustomer.vinNos.splice(index, 1)
    }
    const BatchConfig = () => {
      state.dto.orderInspectionCustomer.orderVehicles = []
      state.pickerForm.vehicles = state.dto.orderInspectionCustomer.vinNos.length
      let index = state.pickerForm.vehicles
      for (let i = 0; i < index; i++) {
        if (state.dto.orderInspectionCustomer.orderVehicles.length < state.pickerForm.vehicles) {
          let data = {
            vinNo: state.dto.orderInspectionCustomer.vinNos[i],
            brand: state.dto.orderInspectionCustomer.brand,
            brandId: state.dto.orderInspectionCustomer.brandId,
            model: state.dto.orderInspectionCustomer.model,
            modelId: state.dto.orderInspectionCustomer.modelId,
            vehicleType: state.pickerForm.vehicleType,
            status: state.pickerForm.status,
            vehBrands: [state.dto.orderInspectionCustomer.brandId, state.dto.orderInspectionCustomer.modelId]
          }
          if (state.dto.orderInspectionCustomer.orderVehicles.includes(data)) index++ // 判断虚拟车架号去重
          else state.dto.orderInspectionCustomer.orderVehicles.push(data), state.dto.orderInspectionCustomer.vinNos.push(data.vinNo)
        } else message.error('输入或添加的车牌号大于车辆台数/总数')
      }

      state.isBatchShow = false
      deleteBtn()
    }
    // 接收对接人传值F
    const getContact = e => {
      if (e.id) {
        state.dto.orderInspectionCustomer.businessContactMobile = Number(e.mobile)
        state.dto.orderInspectionCustomer.businessContactPerson = e.name
        state.contactShow = false
      }
    }
    const closeAddWindow = () => {
      setTimeout(() => {
        contactPersonRef.value.clearData()
      }, 100)
    }
    return {
      picker,
      ...toRefs(state),
      contactPersonRef,
      timeChangeMethod,
      getSendAddress,
      getPickAddress,
      carTypeMethod,
      sameMethod,
      getContact,
      orderDataClean,
      closeAddWindow,
      selectProgrammeMethod,
      startOrderMethod,
      queryOfferMethod,
      setEndAddressMethod,
      setStartAddressMethod,
      vehicleBrandChange,
      deleteVehicle,
      addOneVeh,
      filter,
      createMap,
      mapLine,
      orderDrawerCloseMethod,
      orderSaveMethod,
      vehiclesNumMethod,
      chooseCustomerMethod,
      isTakeCarMethod,
      takeTypeMethodChange,
      pickTypeMethod,
      transDataMethod,
      isPickCarMethod,
      forgeryVinNoMethod,
      cityNameMethod,
      orderOfferApi,
      switchAddressMethod,
      strSplit,
      delVinNo,
      BatchConfig,
      moment,
      deleteBtn,
      disabledDate,
      disabledDateTime,
      historyMethod
    }
  }
}
</script>
<style lang="less" scoped>
@import './index.less';
:deep(.ant-cascader-menu) {
  height: 360px;
}
</style>
